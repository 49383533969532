import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {MatSnackBar} from '@angular/material';
import * as Rx from 'rxjs/Rx';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {SessionService} from '../../../service/session/session.module';
import {LoadingService} from '../../../directive/loading/loading.module';
import {FormControl, Validators, FormBuilder, FormGroup} from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-patient-update',
  templateUrl: './admin-patient-update.component.html',
  styleUrls: ['./admin-patient-update.component.css']
})
export class AdminPatientUpdateComponent implements OnInit {
  complexForm: FormGroup;
  @ViewChild('drawer', {static: true}) drawerCtrl: MatDrawer;

  physicalPerson = {
    main: {
      uuid: '',
      name: '',
      lastname: '',
      surname: ''
    },
    complement: {
      dateBirth: '',
      curp: '',
      rfc: '',
      gender: ''
    },
    system: {
      status: true,
      contacts: [
        {
          main: {
            uuid: '',
            data: '',
            type: 'Telefono'
          },
          system: {
            status: true
          }
        }, {
          main: {
            uuid: '',
            data: '',
            type: 'Celular'
          },
          system: {
            status: true
          }
        }, {
          main: {
            uuid: '',
            data: '',
            type: 'Email'
          },
          system: {
            status: true
          }
        }
      ]
    }
  };

  contactList = [
    {
      main: {
        uuid: '',
        data: '',
        type: 'Telefono de casa'
      },
      system: {
        status: true
      }
    }, {
      main: {
        uuid: '',
        data: '',
        type: 'Celular'
      },
      system: {
        status: true
      }
    }, {
      main: {
        uuid: '',
        data: '',
        type: 'Email'
      },
      system: {
        status: true
      }
    }
  ]

  patientModel = {
    main: {
      uuid: ''
    },
    system: {
      physicalPerson: this.physicalPerson,
      company: {
        main: {
          uuid: ''
        }
      },
      status: true
    }
  };

  metadata = {
    dateBirth: null
  }

  object = JSON.parse(JSON.stringify(this.patientModel));

  constructor(public fb: FormBuilder, protected session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, private router: Router, private activatedRoute: ActivatedRoute) {
    this.complexForm = fb.group({
      'name': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      'lastname': [null, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(100)])],
      'surname': [null, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(100)])],
      'dateBirth': [null, Validators.compose([Validators.required])],
      'gender': [null, Validators.compose([Validators.required])],
      'rfcP': [null, Validators.compose([Validators.pattern('[A-Za-z]{4}[0-9]{6}[A-Za-z0-9]{3}')])],
    })
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['uuid'] == 'new') {
        // no hacemos nada por el momento
      } else {
        this.object.main.uuid = params['uuid'];
        // cargamos los datos de la BD
        this.loadingService.wait();
        this.session.getRequest('patient:get', this.object).subscribe((data: any) => {
            this.object = JSON.parse(JSON.stringify(data.object));
            this.metadata.dateBirth = moment(this.object.system.physicalPerson.complement.dateBirth).utc().toISOString();
            for (let i = 0; i < this.contactList.length; i++) {
              for (let item of this.object.system.physicalPerson.system.contacts) {
                if (this.contactList[i].main.type == item.main.type) {
                  this.contactList[i] = item;
                }
              }
            }
            this.loadingService.hide();
          },
          (error) => {
            console.log('Error:patient:get', error)
            this.loadingService.hide();
          })
      }
    });
  }

  send(object) {
    // pasamos la fecha de compleaño al formato que recibe al BD
    object.system.physicalPerson.complement.dateBirth = moment(this.metadata.dateBirth).format("DD-MM-YYYY");
    object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.loadingService.show(true, "Espere un momento... Se esta guardando el registro.");

    // Se busca el paciente a registrar por su nombre, apellidos y fecha de nacimiento para validar si se encuentra registrado en el sistema.
    console.log('object.main.uuid ', object.main.uuid);
    if (object.main.uuid == '') {
      this.session.getRequest("physicalPerson:findByNameAndSurnameAndLastnameAndDateBirth", object.system.physicalPerson).subscribe(
        (data: any) => {
          if (data.transaction == 'ok') {
            this.snackBar.open('El paciente ya se encuentra registrado', '', {
              duration: 10000
            });
          }
          this.loadingService.hide();
          //this.closeDialog(data.object);
          this.router.navigate(['/admin/patient/list']);
        },
        error => {
          console.log("Error: physicalPerson:findByNameAndSurnameAndLastnameAndDateBirth", error);
          this.loadingService.show(true, "Espere un momento... Se esta guardando el registro.");
          this.object.system.physicalPerson.system.contacts = [];
          for (let item of this.contactList) {
            if (item.main.data != '') {
              object.system.physicalPerson.system.contacts.push(item);
            }
          }
          this.session.getRequest("patient:update", object).subscribe(
            (data: any) => {
              object.main.uuid = data.object.main.uuid;
              this.snackBar.open('El registro se guardo correctamente', '', {
                duration: 8000
              });
              this.loadingService.hide();
              //this.closeDialog(data.object);
              this.router.navigate(['/admin/patient/list']);
            },
            error => {
              console.log("Error: patient:update", error);
              this.loadingService.hide();
            }
          );
          this.loadingService.hide();
        });
    } else {
      this.loadingService.show(true, "Espere un momento... Se esta guardando el registro.");
      this.object.system.physicalPerson.system.contacts = [];
      for (let item of this.contactList) {
        if (item.main.data != '') {
          object.system.physicalPerson.system.contacts.push(item);
        }
      }
      this.session.getRequest("patient:update", object).subscribe(
        (data: any) => {
          object.main.uuid = data.object.main.uuid;
          this.snackBar.open('El registro se guardo correctamente', '', {
            duration: 8000
          });
          this.loadingService.hide();
          //this.closeDialog(data.object);
          this.router.navigate(['/admin/patient/list']);
        },
        error => {
          console.log("Error: patient:update", error);
          this.loadingService.hide();
        }
      );
    }
  }

  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }
}
